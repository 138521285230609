import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import {
  MAGNETO_LOGOUT_HEADER_SCHEMA,
  MAGNETO_LOGIN_HEADER_SCHEMA,
  MAGNETO_HEADER_DRAWER_MENU_SCHEMA
} from "@schemas/layout/header/magnetoUIHeader.schema";
import { TFunction } from "next-i18next";
import { fullBasePath, loggedUserHost, jobsHostBase, HomeHostBase } from "@constants/environment.constant";
import { CandidateContext, JobsContext } from "@context/candidate.context";
import useSignupWidget from "@utils/hooks/useSignupWidget";
import { TRequestSearch } from "#types/filter.core";
import { useSearch } from "@hooksuseSearch";
import { getUrlSignIn } from "@utils/url";
import { generateBreadCrumbs, generateBreadCrumbUrl } from "../../utils/strings/generateBreadCrumbs.util";
import { ESortTypes } from "../../enums";

type TProps = {
  t: TFunction;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const useMagnetoHeader = ({ t, onClose, setFilters, appliedFilters }: TProps) => {
  const [urlSingIn, setUrlSingIn] = useState("");
  const { jobDetailAction, selectedJob, onDetailJobSection } = useContext(JobsContext);
  const { loading, onClickHandle } = useSignupWidget({ t, onClose });
  const [filtersToApply, setFiltersToApply] = useState(appliedFilters);
  const router = useRouter();

  useEffect(() => {
    let urlSingIn;
    if (!onDetailJobSection) {
      urlSingIn = getUrlSignIn({
        jobSlug: "",
        action: jobDetailAction,
        route: router.route,
        t
      });
    } else {
      urlSingIn = getUrlSignIn({
        jobSlug: selectedJob?.jobSlug,
        action: jobDetailAction,
        route: router.route,
        t
      });
    }

    setUrlSingIn(urlSingIn);
  }, [jobDetailAction, onDetailJobSection, router.route, selectedJob?.jobSlug, t]);

  useEffect(() => {
    const {
      paginator: { page, pageSize }
    } = filtersToApply;
    if (pageSize === 0 && page === 0) {
      setFiltersToApply({
        ...appliedFilters,
        paginator: { page: 1, pageSize: 20 },
        order: {
          field: "publish_date",
          order: ESortTypes.DSC
        }
      });
    }
  }, [appliedFilters, filtersToApply]);

  const { urlParam, urlBreadcrumText } = useMemo(() => {
    const [pathname] = router.asPath.split("?");
    return { urlParam: pathname, urlBreadcrumText: pathname.replace(/-/gi, " ") };
  }, [router.asPath]);

  const candidateContext = useContext(CandidateContext);

  const searchbarOnSearch = useSearch({ t, setFilters, appliedFilters: filtersToApply });

  const handleLogIn = useCallback(() => {
    router.push(urlSingIn);
  }, [urlSingIn, router]);

  const handleLogout = useCallback(() => {
    router.push(`${loggedUserHost}/profile/resume/logout`);
  }, [router]);

  const gotSettings = useCallback(() => {
    router.push(`${loggedUserHost}/profile/resume?tab`);
  }, [router]);

  const templateHeaderProps = useMemo(() => {
    //Logout
    const leftTabButtonProps = {
      ...MAGNETO_LOGOUT_HEADER_SCHEMA.leftTabButton,
      text: t(MAGNETO_LOGOUT_HEADER_SCHEMA.leftTabButton.text),
      href: `${jobsHostBase}${MAGNETO_LOGOUT_HEADER_SCHEMA.leftTabButton.href}`
    };

    const rightTabButtonProps = {
      ...MAGNETO_LOGOUT_HEADER_SCHEMA.rightTabButton,
      text: t(MAGNETO_LOGOUT_HEADER_SCHEMA.rightTabButton.text),
      href: `${jobsHostBase}${MAGNETO_LOGOUT_HEADER_SCHEMA.rightTabButton.href}`
    };

    const searchbarProps = {
      termValue: router.query?.q || "",
      placeholder: t(MAGNETO_LOGOUT_HEADER_SCHEMA.searchbar.placeholder),
      onSearch: searchbarOnSearch
    };

    const mobileSearchbarProps = {
      termValue: router.query?.q || "",
      onSearch: searchbarOnSearch
    };

    const signInLinkProps = {
      ...MAGNETO_LOGOUT_HEADER_SCHEMA.signInLink,
      text: t(MAGNETO_LOGOUT_HEADER_SCHEMA.signInLink.text),
      href: urlSingIn
    };

    const signUpButtonProps = {
      ...MAGNETO_LOGOUT_HEADER_SCHEMA.signUpButton,
      buttonText: t(MAGNETO_LOGOUT_HEADER_SCHEMA.signUpButton.buttonText),
      onClick: onClickHandle,
      loadingState: loading
    };

    const listMenuProps = {
      urlParam,
      menuList: MAGNETO_HEADER_DRAWER_MENU_SCHEMA.listMenuProps.menuList.map(({ url, text, slug, isInternal }) => ({
        slug: t(`routes:${slug}`),
        url: isInternal
          ? `${fullBasePath}${t(`routes:${url}`)}`
          : `${url.includes("/home") ? jobsHostBase : HomeHostBase}${url}`,
        text: t(text)
      }))
    };

    const createAccountButtonProps = {
      buttonText: t(MAGNETO_HEADER_DRAWER_MENU_SCHEMA.createAccountButton.buttonText),
      onClick: onClickHandle,
      loadingState: loading
    };

    const loginButtonProps = {
      buttonText: t(MAGNETO_HEADER_DRAWER_MENU_SCHEMA.loginButton.buttonText),
      onClick: handleLogIn
    };

    const dinamycUrl = (url: string, text: string) => {
      switch (text) {
        case "userMenu.help":
          return url;
        case "userMenu.allJobs":
          return `${fullBasePath}${t("routes:jobs")}`;
        default:
          return `${loggedUserHost}${url}`;
      }
    };

    const listMenuUserProps = {
      urlParam,

      menuItems: MAGNETO_LOGIN_HEADER_SCHEMA.menuUser.map((menu) => ({
        ...menu,
        title: t(menu.title),
        items: menu.items.map(({ text, url, icon, slug, ...rest }) => ({
          ...rest,
          icon: icon.src,
          url: dinamycUrl(url, text),
          text: t(text),
          slug: t(slug)
        }))
      })),

      menuItems1440: MAGNETO_LOGIN_HEADER_SCHEMA.menuUser1440.map((menu) => ({
        items: menu.items.map(({ text, icon, ...rest }) => ({
          ...rest,
          icon: icon.src,
          text: t(text)
        }))
      })),

      logout: {
        logoutText: t(MAGNETO_LOGIN_HEADER_SCHEMA.logout),
        onClick: handleLogout
      },

      settings: {
        settingsText: t("userMenu.configuration"),
        onClick: gotSettings
      }
    };

    const profileImage = {
      userImage: candidateContext.candidate?.profileImageFileName || null
    };

    const jobsTabsProps = {
      urlParam,
      headerTabList: MAGNETO_LOGIN_HEADER_SCHEMA.headerTabListJobs.map(({ tabText, url, slug, ...rest }) => ({
        ...rest,
        slug: tabText.includes("userMenu.allJobs") ? t(`routes:${slug}`) : slug,
        url: tabText.includes("userMenu.allJobs") ? `${fullBasePath}${t(`routes:${url}`)}` : `${loggedUserHost}${url}`,
        tabText: t(tabText)
      })),
      addDivider: false
    };

    const processTabsProps = {
      urlParam,
      headerTabList: MAGNETO_LOGIN_HEADER_SCHEMA.headerTabListProcess.map(({ tabText, url, ...rest }) => ({
        ...rest,
        url: `${loggedUserHost}${url}`,
        tabText: t(tabText)
      })),
      addDivider: true
    };

    const curriculumTabProps = {
      urlParam,
      headerTabList: MAGNETO_LOGIN_HEADER_SCHEMA.headerTabListCV.map(({ tabText, url, ...rest }) => ({
        ...rest,
        url: `${loggedUserHost}${url}`,
        tabText: t(tabText)
      })),
      addDivider: true
    };

    const homeUrl = `${jobsHostBase}/es`;

    const breadCrumbsList = generateBreadCrumbs(urlBreadcrumText);

    const breadcrumbProps = {
      breadcrumbText: urlBreadcrumText,
      baseUrl: `${fullBasePath}/`,
      haveRedirect: true,
      detailTitle: router.query.jobSlug ? selectedJob?.title : "",
      breadCrumbFromServer: breadCrumbsList,
      urlFromServer: generateBreadCrumbUrl(breadCrumbsList, `${fullBasePath}/`)
    };

    return {
      leftTabButtonProps,
      rightTabButtonProps,
      searchbarProps,
      mobileSearchbarProps,
      signInLinkProps,
      signUpButtonProps,
      breadcrumbProps,
      listMenuProps,
      createAccountButtonProps,
      loginButtonProps,
      listMenuUserProps,
      profileImage,
      jobsTabsProps,
      processTabsProps,
      curriculumTabProps,
      homeUrl
    };
  }, [
    urlSingIn,
    t,
    router.query,
    searchbarOnSearch,
    onClickHandle,
    loading,
    urlBreadcrumText,
    urlParam,
    handleLogIn,
    handleLogout,
    gotSettings,
    candidateContext.candidate?.profileImageFileName,
    selectedJob
  ]);

  const {
    leftTabButtonProps,
    rightTabButtonProps,
    searchbarProps,
    mobileSearchbarProps,
    signInLinkProps,
    signUpButtonProps,
    breadcrumbProps,
    listMenuProps,
    createAccountButtonProps,
    loginButtonProps,
    listMenuUserProps,
    profileImage,
    jobsTabsProps,
    processTabsProps,
    curriculumTabProps,
    homeUrl
  } = templateHeaderProps;

  const logoutPropsHeader = {
    logoutHeaderProps: {
      homeUrl,
      leftTabButton: leftTabButtonProps,
      rightTabButton: rightTabButtonProps,
      searchbar: searchbarProps,
      mobileSearchbar: mobileSearchbarProps,
      signInLink: signInLinkProps,
      signUpButton: signUpButtonProps,
      breadcrumbProps,
      allJobsText: t("drawerMenu.allJobs"),
      allJobsLink: `${fullBasePath}${t(`routes:jobs`)}`,
      hasMobileSearchBarButton: true
    },
    headerDrawerMenuProps: {
      headerDrawerProps: {
        homeUrl,
        leftTabLink: leftTabButtonProps,
        rightTabLink: rightTabButtonProps
      },
      listMenuProps: listMenuProps,
      createAccountButton: createAccountButtonProps,
      loginButton: loginButtonProps
    }
  };

  const loginPropsHeader = {
    listMenuProps,
    listMenuUserProps,
    breadcrumbProps,
    profileImage,
    jobsTabsProps,
    processTabsProps,
    curriculumTabProps,
    searchbar: searchbarProps,
    MobileSearchbarProps: mobileSearchbarProps,
    homeUrl
  };

  const asideMenu = {
    headerDrawerMenuProps: {
      headerDrawerProps: {
        homeUrl,
        leftTabLink: leftTabButtonProps,
        rightTabLink: rightTabButtonProps
      },
      listMenuProps: listMenuProps,
      createAccountButton: createAccountButtonProps,
      loginButton: loginButtonProps
    }
  };

  return {
    logoutPropsHeader,
    loginPropsHeader,
    asideMenu
  };
};

export default useMagnetoHeader;

import {
  SPEResolution,
  authorizationRegulations,
  blog,
  consentReferrals,
  cookiePolicyMagneto,
  cookiePolicyPsyconometrics,
  frequentQuestions,
  fullBasePath,
  loginUrl,
  marbleUrl,
  noticeOfPrivacyMagneto,
  noticeOfPrivacyPsyconometrics,
  personalDataMagneto,
  personalDataPsyconometrics,
  digitalLibrarysUrl,
  termsAndConditions,
  digitalSelection,
  talentAssessment,
  getConsultancy,
  postJobOffer,
  pymesPlans,
  supportZendesk
} from "@constants";

export const footerMagnetoUI = {
  personsMenu: {
    heightContent: 330,
    isLoading: false,
    title: "footer:titles.persons",
    isAuthenticated: false,
    links: [
      {
        tag: "footer:links.allJobs",
        routePath: `routes:jobs`,
        baseUrl: fullBasePath,
        isExternal: false
      },
      {
        tag: "footer:links.login",
        routePath: "",
        baseUrl: loginUrl,
        isExternal: true
      },
      {
        tag: "footer:links.registerResume",
        routePath: "",
        baseUrl: "",
        isExternal: false,
        isCreateAccount: true
      },
      {
        tag: "footer:links.blog",
        routePath: "",
        baseUrl: blog,
        isExternal: true
      },
      {
        tag: "footer:links.frequentQuestions",
        routePath: "",
        baseUrl: frequentQuestions,
        isExternal: true
      },
      {
        tag: "footer:links.jobsByCity",
        routePath: "routes:byCity",
        baseUrl: "",
        isExternal: false
      },
      {
        tag: "footer:links.jobsByIndustry",
        routePath: "routes:bySector",
        baseUrl: "",
        isExternal: false
      },
      {
        tag: "footer:links.jobsByCompany",
        routePath: "routes:byCompany",
        baseUrl: "",
        isExternal: false
      },
      {
        tag: "footer:links.jobsByProfessionalPositionInCities",
        routePath: "routes:ocupacol",
        baseUrl: "",
        isExternal: false
      },
      {
        tag: "footer:links.support",
        routePath: "",
        baseUrl: supportZendesk,
        isExternal: true,
        openNewTab: true
      }
    ]
  },
  magnetoMenu: {
    heightContent: 140,
    title: "footer:titles.magneto",
    links: [
      {
        tag: "footer:links.digitalSelection",
        routePath: "",
        baseUrl: digitalSelection,
        isExternal: true
      },
      {
        tag: "footer:links.talentAssessment",
        routePath: "",
        baseUrl: talentAssessment,
        isExternal: true
      },
      {
        tag: "footer:links.getConsultancy",
        routePath: "",
        baseUrl: getConsultancy,
        isExternal: true
      }
    ]
  },
  magnetoLiteMenu: {
    heightContent: 115,
    title: "footer:titles.magnetoLite",
    links: [
      {
        tag: "footer:links.postJobOffer",
        routePath: "",
        baseUrl: postJobOffer,
        isExternal: true
      },
      {
        tag: "footer:links.pymesPlans",
        routePath: "",
        baseUrl: pymesPlans,
        isExternal: true
      }
    ]
  },
  otherSolutionsMenu: {
    heightContent: 115,
    title: "footer:titles.otherSolutions",
    links: [
      {
        tag: "footer:links.marble",
        routePath: "",
        baseUrl: marbleUrl,
        isExternal: true
      },
      {
        tag: "footer:links.library",
        routePath: "",
        baseUrl: digitalLibrarysUrl,
        isExternal: true
      }
    ]
  },
  legalMenu: {
    heightContent: 335,
    title: "footer:titles.legal",
    links: [
      {
        tag: "footer:links.personalDataPsyconometrics",
        routePath: "",
        baseUrl: personalDataPsyconometrics,
        isExternal: true
      },
      {
        tag: "footer:links.personalDataMagneto",
        routePath: "",
        baseUrl: personalDataMagneto,
        isExternal: true
      },
      {
        tag: "footer:links.noticeOfPrivacyPsyconometrics",
        routePath: "",
        baseUrl: noticeOfPrivacyPsyconometrics,
        isExternal: true
      },
      {
        tag: "footer:links.noticeOfPrivacyMagneto",
        routePath: "",
        baseUrl: noticeOfPrivacyMagneto,
        isExternal: true
      },
      {
        tag: "footer:links.termsAndConditions",
        routePath: "",
        baseUrl: termsAndConditions,
        isExternal: true
      },
      {
        tag: "footer:links.SPEResolution",
        routePath: "",
        baseUrl: SPEResolution,
        isExternal: true
      },
      {
        tag: "footer:links.consentReferrals",
        routePath: "",
        baseUrl: consentReferrals,
        isExternal: true
      },
      {
        tag: "footer:links.authorizationRegulations",
        routePath: "",
        baseUrl: authorizationRegulations,
        isExternal: true
      },
      {
        tag: "footer:links.cookiePolicyPsyconometrics",
        routePath: "",
        baseUrl: cookiePolicyPsyconometrics,
        isExternal: true
      },
      {
        tag: "footer:links.cookiePolicyMagneto",
        routePath: "",
        baseUrl: cookiePolicyMagneto,
        isExternal: true
      }
    ]
  }
};

import { FC, Fragment, useCallback, useContext, useMemo, useState } from "react";
import dynamic from "next/dynamic";
import Layout from "antd/lib/layout";
import { useIsScrolledById, useIsWidgetToApply } from "@hooks";
import { EWidgetType } from "@enums";
import HeaderButtons from "../children/buttons.wrap";
import styles from "./jobOffersHeaderType.module.scss";
import { AuthContext } from "@context";
import classNames from "classnames";

const { Header } = Layout;
const headerClass = "job-offers-header";

const JobSiteHeader: FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { isAuthenticated } = useContext(AuthContext);
  const scrolled = useIsScrolledById("jobs-content--left");

  const isWidgetIframe = useIsWidgetToApply(EWidgetType.iframe);

  const onClose = useCallback(() => setShowMenu((show) => !show), [setShowMenu]);

  const displayBurgerIcon = useMemo(() => {
    if (isWidgetIframe) return <Fragment />;
    const Burger = dynamic(() => import("../children/burger.component"));
    return <Burger isOpen={showMenu} handleShow={onClose} size="22px" color="#2e2d2d" thick="2px" />;
  }, [isWidgetIframe, onClose, showMenu]);

  const displayLinkLogo = useMemo(() => {
    if (isWidgetIframe) return <Fragment />;
    const LinkLogo = dynamic(() => import("../children/searchHit/linkLogo/component"));
    return <LinkLogo margin="15px" />;
  }, [isWidgetIframe]);

  const displayMenu = useMemo(() => {
    if (isAuthenticated) {
      const MenuComponent = dynamic(() => import("../children/menuProfile/menu.component"));
      return <MenuComponent />;
    }
  }, [isAuthenticated]);

  const displayMenuSearchHit = useMemo(() => {
    if (showMenu) {
      const MenuSearchHit = dynamic(() => import("../children/searchHit/menuSearchHit/component"));
      return <MenuSearchHit open={showMenu} onClose={onClose} />;
    }
    return <Fragment />;
  }, [showMenu, onClose]);

  return (
    <Header className={classNames(styles[headerClass], styles.header, { [styles["hide-header"]]: scrolled })}>
      <div className={styles[`${headerClass}__content-container`]}>
        {displayBurgerIcon}
        {displayLinkLogo}
        {displayMenu}
      </div>

      <HeaderButtons />
      {displayMenuSearchHit}
    </Header>
  );
};

export default JobSiteHeader;

import { useCallback, useContext, useMemo } from "react";
import { JobsContext } from "@contextjobs.context";
import { NORESULTSOPTIONS } from "@schemasnoResults";
import { fillUrlUtms, getQueyParams } from "@utilsurl";
import { TFunction } from "next-i18next";
import { useRouter } from "next/router";
import { isNonEmptyArray } from "ramda-adjunct";
import CharacterIconWithSession from "@public/assets/images/emptyResultWithSession.png";

type TProps = {
  t: TFunction;
  isAuthenticated: boolean;
};

const useMagnetoEmptyResults = ({ t, isAuthenticated }: TProps) => {
  const context = useContext(JobsContext);

  const { query } = useRouter();
  const querySearch = getQueyParams("q", query);

  const filtersApplied = context.filtersApplied.filters;
  const activeFilter = useCallback(() => {
    for (const filter in filtersApplied) {
      if (isNonEmptyArray(filtersApplied[filter])) {
        return filter;
      }
    }
  }, [filtersApplied]);

  const titleNoResutls = t<string>(
    `jobOffers:${`noResultsWithSession.${activeFilter() ? "titleNoResultFilter" : "titleNoResultQuery"}`}`,
    { searchTerm: querySearch }
  );

  const noResultsDescription = t<string>(`jobOffers:${"noResultsWithSession.noResultsDescription"}`);

  const noResultsDescriptionTwo = t<string>(`jobOffers:noResultsWithSession.noResultsDescriptionTwo`);
  const filteredOptions = isAuthenticated ? NORESULTSOPTIONS : NORESULTSOPTIONS.slice(1);

  const emptyResultsProps = useMemo(() => {
    titleNoResutls;
    noResultsDescriptionTwo;
    noResultsDescription;
    filteredOptions;
    return {
      titleNoResutls,
      noResultsDescriptionTwo,
      noResultsDescription,
      noResultsImage: CharacterIconWithSession.src,
      filteredOptions: filteredOptions.map(({ tag, url, baseUrl, isExternal }) => ({
        tag: t(tag),
        url: isExternal ? fillUrlUtms(url, query) : fillUrlUtms(`${baseUrl}${t<string>(url)}`, query)
      }))
    };
  }, [filteredOptions, titleNoResutls, noResultsDescription, noResultsDescriptionTwo, t, query]);

  return { emptyResultsProps };
};
export default useMagnetoEmptyResults;
